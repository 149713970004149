.confirmationBlock {
    padding: 25px 25px;
    position: static;
}

@media only screen and (min-width: 769px) {
    .confirmationBlockFixed {
        position: fixed;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding: 25px 25px;
        max-width: 430px;
        top: 0;
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .confirmationBlockFixed {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding: 25px 25px;
        margin-top: 15px;
        position: static;
    }
}

.confirmationBlock a,
.confirmationBlockFixed a {
    color: #ff6a13;
}

.confirmationBlock a:hover,
.confirmationBlockFixed a:hover {
    color: #0097b3;
}

.checkbox {
    margin-bottom: 30px;
}

.noticeContainer {
    background: #ff6a13;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #fff;
    border-radius: 15px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    position: relative;
}

.noticeContainer::before {
    content: '';
    position: absolute;
    top: -15px;
    left: calc(50% - 6px);
    border: 7px solid transparent;
    border-bottom: 9px solid #ff6a13;
}

.noticeArrow {
    display: block;
    width: 0.7vw;
    height: 0.7vw;
    position: absolute;
    top: 0;
    left: 50%;
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: #ff6a13;
    max-width: 12px;
    max-height: 12px;
}

.confirmationBlockFixed button,
.confirmationBlock button {
    width: 100%;
}

.confirmationBlockFixed button:disabled,
.confirmationBlock button:disabled {
    background: #dde4e5;
    color: #b6c1c2;
    cursor: default;
    pointer-events: none;
    border-color: #dde4e5;
}

.onlineRegistrationWarning {
    padding-top: 30px;
}
